import blog1 from  '../img/blogs/blog1.svg';
import blog3 from  '../img/blogs/blog3.svg';
import blog2 from  '../img/blogs/blog2.svg';
import blog4 from  '../img/blogs/blog4.svg';

const blogs = [
    {
        id: 1,
        title: 'How To Work from Home',
        date: '01',
        month: 'April',
        image: blog1,
        link: 'https://www.facebook.com/newit.com.bd/'
      
    },
    {
        id: 2,
        title: 'How To Use SEO Efficiently',
        date: '01',
        month: 'April',
        image: blog2,
        link: 'https://www.facebook.com/newit.com.bd/'
       
    },
    {
        id: 3,
        title: 'How To Get More SMS By Fb Boost',
        date: '01',
        month: 'April',
        image: blog3,
        link: 'https://www.facebook.com/newit.com.bd/'
        
    },
    {
        id: 4,
        title: 'How To Make Your website Fast',
        date: '01',
        month: 'April',
        image: blog4,
        link: 'https://www.facebook.com/newit.com.bd/'
        
    },
   
]

export default blogs;