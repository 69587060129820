import img1 from '../img/portImages/maya-1.jpg';
import img2 from '../img/portImages/maya-2.jpg';
import img3 from '../img/portImages/maya-3.jpg';
import img4 from '../img/portImages/maya-4.jpg';

const portfolios = [
    {
        id: 1,
        category: 'Development',
        image: img1,
        link1: 'https://www.flickr.com/photos/rakibulislamju/albums',
        link2: 'https://www.behance.net/rakibulislamju',
        title: 'Design & Development',
        text: 'Web Design & Development, App Design & Development, Software Design & Development'
    },
    {
        id: 2,
        category: 'Graphics',
        image: img2,
        link1: 'https://www.flickr.com/photos/rakibulislamju/albums',
        link2: 'https://www.behance.net/rakibulislamju',
        title: 'Graphic Design',
        text: 'Logo & Brand Identity, Poster, Cover, Flyer, UI UX & Etc. Print Design'
    },
    {
        id: 3,
        category: 'Animation Video',
        image: img3,
        link1: 'https://www.flickr.com/photos/rakibulislamju/albums',
        link2: 'https://www.behance.net/rakibulislamju',
        title: 'Animation Video',
        text: 'Animation Videos, Different Festival Videos, Log, Intro, outro, Slide Show Video'
    },
    {
        id: 4,
        category: 'Digital Marketing',
        image: img4,
        link1: 'https://www.flickr.com/photos/rakibulislamju/albums',
        link2: 'https://www.behance.net/rakibulislamju',
        title: 'Digital Marketing',
        text: 'Social Marketing, Bulk SMS, Search Engine Optimization (SEO)'
    },
 
]

export default portfolios;