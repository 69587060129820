import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
   

    apiKey: "AIzaSyA5lV05wRY2k4aBrBS6hcLF4D20H1X63DU",
    authDomain: "rakibul-islam-contact.firebaseapp.com",
    projectId: "rakibul-islam-contact",
    storageBucket: "rakibul-islam-contact.appspot.com",
    messagingSenderId: "165393359361",
    appId: "1:165393359361:web:f2750964dc8eceadaa5600"
 
});

var db = firebaseApp.firestore();

export { db };
