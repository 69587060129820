import React from 'react'
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import ReviewItem from '../Components/ReviewItem';

function ReviewsSetion() {
    return (
        <ReviewsStyled>
            <Title title={'Reviews'} />
            <InnerLayout>
                <div className="reviews">
                    <ReviewItem 
                        text={'It was my first experience placing order in fiver and Rakibul was really great. I am really happy with his work and communication. I will recommend him in future."Sayed Mahbub Morshed  Founder & Director CBG Global"'} 
                   
                     />
                    <ReviewItem 
                        text={'When working with Rakibul Islam, it is a very happy moment.Working with him has been a true pleasure. Ihave rather work with him than anybody else. Thank you once more for the fantastic poster design'} 
                    />
                    
                </div>
            </InnerLayout>
        </ReviewsStyled>
    )
}

const ReviewsStyled = styled.section`
    .reviews{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
        width: 100%;
        @media screen and (max-width:650px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
`;

export default ReviewsSetion;
