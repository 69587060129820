import React from 'react';
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import SmallTitle from '../Components/SmallTitle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';
import ResumeItem from '../Components/ResumeItem';
import PrimaryButton from './PrimaryButton';

function Resume() {
    const briefcase = <BusinessCenterIcon />
    const school = <SchoolIcon />
    return (
        <ResumeStyled>
            <Title title={'Resume'} />
            <InnerLayout>
            <div className="small-title u-small-title-margin">
                    <SmallTitle icon={school} title={'Educational Qualifications'} />
                </div>
                <div className="resume-content ">
                    <ResumeItem 
                        year={'2019 - 2021'} 
                        title={'M.Sc. In Computer Science'}
                        subTitle={'Jahangirnagar University'}
                        text={'Jahangirnagar University is one of Bangladesh"s oldest universities, located in Savar, near Dhaka. It is a residential university. It was initially inaugurated on January 12, 1971, by its original judge, Governor of Above East Pakistan, Rear Admiral S.M. Ahsan. '} 
                    />
                    <ResumeItem 
                        year={'2015 - 2019'} 
                        title={'B.Sc. In Computer Science & Engineering'}
                        subTitle={'Daffodil International University'}
                        text={'Daffodil University. Being established in 2002, Daffodil International University has become a prominent private university in Bangladesh. '} 
                    />
                    <ResumeItem 
                        year={'2011 - 2013'} 
                        title={'H.S.C'}
                        subTitle={'Adamjee Cantonment College'}
    
                         />
                     <ResumeItem 
                        year={'2009 - 2011'} 
                        title={'S.S.C'}
                        subTitle={'Mirpur Bangla High School'}
                        
                        
    
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />
                </div>
                <br></br>
                <br></br>
                <div className="small-title">
                    <SmallTitle icon={briefcase} title={'Working Experience'} />
                </div>
                <div className="resume-content">
                <ResumeItem 
                        year={'2020 - Present'} 
                        title={'Founder & CEO'}
                        subTitle={'New IT'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />
                   <ResumeItem 
                        year={'2017 - 2020'} 
                        title={'Graphic & Web Designer'}
                        subTitle={'Akaar IT Ltd'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />
                    <ResumeItem 
                        year={'2016 - 2017'} 
                        title={'Video Editor & Graphics Design'}
                        subTitle={'Foresight IT'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />                     
                    
                   <ResumeItem 
                        year={'2015 - 2016'} 
                        title={'IT consultant & Tutor'}
                        subTitle={'Charupath Coaching Center'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />  
                </div>
                 <br></br>
                <br></br>
                <div className="small-title">
                    <SmallTitle icon={briefcase} title={'Professional Training Information'} />
                </div>
                <div className="resume-content">
                   
                    <ResumeItem 
                        year={'2015 - 2017'} 
                        title={'Web Design and Development'}
                        subTitle={'Daffodil International University'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />  
                     <ResumeItem 
                        year={'2015 - 2016'} 
                        title={'Professional Graphics Design Training'}
                        subTitle={'SR Institute of Design'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />                     
                </div>
                <br></br>
                <br></br>
                <div className="small-title">
                    <SmallTitle icon={briefcase} title={'Achievements'} />
                </div>
                <div className="resume-content">
                   
                    <ResumeItem 
                        year={'2020'} 
                        title={'Covid19-novel Coronavirus Certificate'}
                        subTitle={'General Of Health Services, Brac & UK Public Health Rapid Support Team'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />  
                     <ResumeItem 
                        year={'2019'} 
                        title={'Koica-Certificate'}
                        subTitle={'Korea International Cooperation Agency '}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />   
                     <ResumeItem 
                        year={'2011'} 
                        title={'Prime Minister Certification'}
                        subTitle={'Prime Minister Sheikh Hasina'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />  
                     <ResumeItem 
                        year={'2008'} 
                        title={'Junior government scholarship'}
                        subTitle={'Nowpara High School'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />   
                     <ResumeItem 
                        year={'2005'} 
                        title={'Primary Government Scholarship'}
                        subTitle={'Khamar Para Gov Primary School'}
                        text={'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa nihil impedit natus nostrum? Velit accusantium id quos, nihil vel quod.Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. '} 
                    />   
                                                     
                </div>
              
            </InnerLayout>
            <a href="https://drive.google.com/file/d/1pdpjhf-AVdouMflPFOJ-BAkUoy9tpKJY/view?usp=sharing" target="_blank" className="btn btn-smart">
                            
                  <PrimaryButton title={'Download Cv'} />
            
              </a>
        </ResumeStyled>    
    )
}

const ResumeStyled = styled.section`
    .small-title{
        padding-bottom: 3rem;
    }
    .u-small-title-margin{
        margin-top: -1rem;
    }

    .resume-content{
        border-left: 2px solid var(--border-color);
    }
`;
export default Resume
