import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {MainLayout, InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import PrimaryButton from '../Components/PrimaryButton';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContactItem from '../Components/ContactItem';
import { db } from '../Components/firebase';
import { colors } from '@material-ui/core';


    const ContactPage = () => {
    const phone = <PhoneIcon />
    const email1 = <EmailIcon />
    const location = <LocationOnIcon />

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
  
    const [loader, setLoader] = useState(false);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoader(true);
  
      db.collection("contacts")
        .add({
          name: name,
          email: email,
          message: message,
          subject:subject,
        })
        .then(() => {
          setLoader(false);
          alert("Your message has been submitted👍");
        })
        .catch((error) => {
          alert(error.message);
          setLoader(false);
        });
  
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    };
    return (
        <MainLayout>
            <Title title={'Contact'} />
            <ContactPageStyled >
            <InnerLayout className={'contact-section'}>
                <div className="left-content">
                    <div className="contact-title">
                        <h4>Get In Touch 📱</h4>
                    </div>
                    <form  className="form"onSubmit={handleSubmit}>
                   
                        <div className="form-field">
                            <label htmlFor="name"  >Enter your name*</label>
                            <input placeholder="Name"value={name}onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-field">
                            <label htmlFor="email"  >Enter your email*</label>
                            <input placeholder="Email"value={email}onChange={(e) => setEmail(e.target.value)}
      />
                        </div>
                        <div className="form-field">
                            <label htmlFor="subject"  >Enter your subject</label>
                            
                            <input placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)}
      />
                        </div>
                        <div className="form-field">
                            <label htmlFor="text-area">Enter your Message*</label>
                            <textarea
                                placeholder="Message"value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                        </div>
                            <br></br>
                        <button
                          type="submit"style={{ background: loader ? "" : " #007BFF" }} > ◀️ SUBMIT YOUR INFO ▶️ 
                         </button>
                    </form>
                    <br></br>
                </div>
                
                <div className="right-content">
                    <ContactItem title={'Phone'} icon={phone} cont1={'+8801889999947'} cont2={'+8809696999947'} />
                    <ContactItem title={'Email'} icon={email1} cont1={'rakibulislamju@gmail.com'} cont2={'info@rakibul.com.bd'} />
                    <ContactItem title={'Address'} icon={location} cont1={'Pollibiddut,Savar 1344,Bangladesh'} cont2={''} />
                    
                </div>
            </InnerLayout>
            </ContactPageStyled>
        </MainLayout>
    )
}

const ContactPageStyled = styled.section`
    .contact-section{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2rem;
        @media screen and (max-width: 978px){
            grid-template-columns: repeat(1, 1fr);
            .f-button{
                margin-bottom: 3rem;
            }
        }
        .right-content{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            @media screen and (max-width: 502px){
                width: 70%;
            }
        }
        .contact-title{
            h4{
                color: var(--white-color);
                padding: 1rem 0;
                font-size: 1.8rem;
            }
        }
        .form{
            width: 100%;
            @media screen and (max-width: 502px){
                width: 100%;
            }
            .form-field{
                margin-top: 2rem;
                position: relative;
                width: 100%;
                label{
                    position: absolute;
                    left: 20px;
                    top: -19px;
                    display: inline-block;
                    background-color: var(--background-dark-color);
                    padding:0 .5rem;
                    color: inherit;
                }
                input{
                    border: 1px solid var(--border-color);
                    outline: none;
                    background: transparent;
                    height: 50px;
                    padding:0 15px;
                    width: 100%;
                    color: inherit;
                }
                textarea{
                    background-color: transparent;
                    border: 1px solid var(--border-color);
                    outline: none;
                    color: inherit;
                    width: 100%;
                    padding: .8rem 1rem;
                }
            }

            
        }
    }
`;

export default ContactPage
